import { useState, useEffect } from 'react';
import axiosInstance from '../../api/axios';

const ListCowMealsDialog = ({ selectedCowData, isDialogOpen, setIsDialogOpen }) => {
  const [cowMeals, setCowMeals] = useState([]);
  const [calculationHelpers, setCalculationHelpers] = useState({
    cowMealIndex: '',
    cowExtraMealCategory: '',
    cowFulfillMilk: 0
  });

  const [mealPrice, setMealPrice] = useState({
    main: 0,
    extra: 0
  });

  const [mealQuantity, setMealQuantity] = useState({
    main: 0,
    extra: 0
  });

  const getNutrients = async () => {
    const response = await axiosInstance.get(`cow/${selectedCowData.id}`);
    setCowMeals({
      main: !response.data?.meals[0]?.isExtra ? response.data?.meals[0] : response.data?.meals[1],
      extra: response.data?.meals[0]?.isExtra ? response.data?.meals[0] : response.data?.meals[1]
    });
  };

  useEffect(() => {
    getNutrients();
  }, [selectedCowData]);

  useEffect(() => {
    setCalculationHelpers({
      cowMealIndex: selectedCowData.mass / 100,
      cowExtraMealCategory:
        cowMeals.extra?.category === '5'
          ? 2
          : cowMeals.extra?.category === '6'
          ? 2.5
          : cowMeals.extra?.category === '7'
          ? 3
          : 0,
      cowFulfillMilk:
        selectedCowData.averageDailyMilk - cowMeals.main?.milkLitres >= 0
          ? selectedCowData.averageDailyMilk - cowMeals.main?.milkLitres
          : 0
    });
    setMealPrice({
      main: cowMeals.main?.nutrients.reduce(
        (acc, el) => acc + el.price * el.mealnutrient.nutrientQuantity,
        0
      ),
      extra: cowMeals.extra?.nutrients.reduce(
        (acc, el) => acc + (el.price * el.mealnutrient.nutrientQuantity) / 100,
        0
      )
    });
  }, [cowMeals]);

  useEffect(() => {
    setMealQuantity({
      main: cowMeals.main?.nutrients.reduce(
        (acc, el) => acc + (el.mealnutrient.nutrientQuantity * selectedCowData.mass) / 100,
        0
      ),
      extra: cowMeals.extra?.nutrients.reduce(
        (acc, el) =>
          acc +
          (calculationHelpers.cowFulfillMilk === 0
            ? el.mealnutrient.nutrientQuantity / 100
            : ((el.mealnutrient.nutrientQuantity / 100) * calculationHelpers.cowFulfillMilk) /
              calculationHelpers.cowExtraMealCategory),
        0
      )
    });
  }, [calculationHelpers]);

  return (
    <div className="wrapper">
      <div className="dialog listcowmeals">
        <div className="dialog__header">Lista obroka</div>
        <div className="dialog__body">
          {cowMeals.main && (
            <table className="list-cow-meals__item">
              <tbody>
                <tr>
                  <td colSpan={3}>Osnovni obrok</td>
                </tr>
                <tr>
                  <th>Hranivo</th>
                  <th>Količina</th>
                  <th>Cena</th>
                </tr>
                {cowMeals.main?.nutrients.map((el) => (
                  <tr key={el.id}>
                    <td>{el.name}</td>
                    <td className="align-right">
                      {(el.mealnutrient.nutrientQuantity * calculationHelpers.cowMealIndex).toFixed(
                        2
                      )}{' '}
                      kg
                    </td>
                    <td className="align-right">
                      {(
                        el.price *
                        el.mealnutrient.nutrientQuantity *
                        calculationHelpers.cowMealIndex
                      ).toFixed(1)}{' '}
                      rsd
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    style={{
                      borderTop: '1px solid #30638e66',
                      borderRight: '1px solid #30638e66'
                    }}>
                    Obrok zadovoljava do <b>{cowMeals.main.milkLitres}l</b> mleka
                  </td>
                  <td
                    className="align-right"
                    style={{
                      borderTop: '1px solid #30638e66',
                      borderRight: '1px solid #30638e66'
                    }}>
                    <b>{mealQuantity.main?.toFixed(0)} kg</b>
                  </td>
                  <td
                    className="align-right"
                    style={{
                      borderTop: '1px solid #30638e66',
                      borderRight: '1px solid #30638e66'
                    }}>
                    Ukupna cena obroka:{' '}
                    <b>{Math.ceil(mealPrice.main * calculationHelpers.cowMealIndex)} rsd</b>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <hr />
          {cowMeals.main && cowMeals.extra && (
            <table className="list-cow-meals__item">
              <tbody>
                <tr>
                  <td colSpan={3}>Dopunski obrok</td>
                </tr>
                <tr>
                  <th>Hranivo</th>
                  <th>Količina</th>
                  <th>Cena</th>
                </tr>
                {cowMeals.extra?.nutrients.map((el) => (
                  <tr key={el.id}>
                    <td>{el.name}</td>
                    <td className="align-right">
                      {selectedCowData.averageDailyMilk <= cowMeals.main?.milkLitres
                        ? (el.mealnutrient.nutrientQuantity / 100).toFixed(2)
                        : (
                            (el.mealnutrient.nutrientQuantity *
                              (calculationHelpers.cowFulfillMilk /
                                calculationHelpers.cowExtraMealCategory)) /
                            100
                          ).toFixed(2)}{' '}
                      kg
                    </td>
                    <td className="align-right">
                      {selectedCowData.averageDailyMilk <= cowMeals.main?.milkLitres
                        ? ((el.price * el.mealnutrient.nutrientQuantity) / 100).toFixed(1)
                        : (
                            (el.price *
                              ((calculationHelpers.cowFulfillMilk /
                                calculationHelpers.cowExtraMealCategory) *
                                el.mealnutrient.nutrientQuantity)) /
                            100
                          ).toFixed(1)}{' '}
                      rsd
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    style={{
                      borderTop: '1px solid #30638e66',
                      borderRight: '1px solid #30638e66'
                    }}>
                    Obrok sadrži
                    <b>
                      {cowMeals?.extra?.category === '5'
                        ? ' 12% '
                        : cowMeals?.extra?.category === '6'
                        ? ' 15% '
                        : cowMeals?.extra?.category === '7'
                        ? ' 18% '
                        : ''}
                    </b>
                    proteina
                  </td>
                  <td
                    className="align-right"
                    style={{
                      borderTop: '1px solid #30638e66',
                      borderRight: '1px solid #30638e66'
                    }}>
                    <b>{mealQuantity.extra?.toFixed(2)} kg</b>
                  </td>
                  <td
                    className="align-right"
                    style={{
                      borderTop: '1px solid #30638e66',
                      borderRight: '1px solid #30638e66'
                    }}>
                    Ukupna cena obroka:{' '}
                    <b>
                      {selectedCowData.averageDailyMilk <= cowMeals.main?.milkLitres
                        ? mealPrice.extra?.toFixed()
                        : Math.ceil(
                            mealPrice.extra *
                              (calculationHelpers.cowFulfillMilk /
                                calculationHelpers.cowExtraMealCategory)
                          ).toFixed()}{' '}
                      rsd
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div className="dialog__footer">
          <button>Štampaj</button>
          <button onClick={() => setIsDialogOpen({ ...isDialogOpen, listMeal: false })}>
            Zatvori
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListCowMealsDialog;
