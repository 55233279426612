import React from 'react';

const NutrientTabs = ({ selectedTab, setSelectedTab }) => {
  const handleTabSelection = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  const tabs = [
    {
      id: 1,
      name: 'Osnovni'
    },
    {
      id: 2,
      name: 'Dopunski'
    },
    {
      id: 3,
      name: 'Mešoviti'
    },
    {
      id: 4,
      name: 'Za koncentrat'
    }
  ];

  return (
    <div className="nutrient-table__tab-wrapper">
      {tabs.map((el) => (
        <div
          key={el.id}
          className={selectedTab === el.id ? 'nutrient-table__tab--active' : 'nutrient-table__tab'}
          onClick={() => handleTabSelection(el.id)}>
          {el.name}
        </div>
      ))}
    </div>
  );
};

export default NutrientTabs;
