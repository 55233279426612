import { useState, useEffect } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import DeleteMealDialog from './DeleteMealDialog';
import EditMealDialog from './EditMealDialog';

const MealCategory = ({
  getMeals,
  getExtraMeals,
  data,
  categoryId,
  categoryName,
  categoryType
}) => {
  const [isAccordionCollapsed, setIsAccordionCollapsed] = useState(true);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteMealDialogOpen, setIsDeleteDialogMealOpen] = useState(false);
  const [dialogData, setDialogData] = useState([]);
  const TABLE_MEASUREMENT_CONSTANT =
    categoryType === '1' ? 'Količina (kg/100kg)' : categoryType === '2' ? 'Količina (%/100%)' : '';
  const NUTRIENT_MEASUREMENT_CONSTANT =
    categoryType === '1' ? 'kg' : categoryType === '2' ? '%' : '';

  const filteredData = data?.filter((el) => el.category === categoryId);

  const handleDeleteMealDialog = (el) => {
    setDialogData(el);
    setIsDeleteDialogMealOpen(!isDeleteMealDialogOpen);
  };

  const handleEditMealDialog = (el) => {
    setDialogData(el);
    setIsEditDialogOpen(!isEditDialogOpen);
  };

  useEffect(() => {
    if (categoryType === '1') {
      getMeals();
    }
    if (categoryType === '2') {
      getExtraMeals();
    }
  }, [isEditDialogOpen, isDeleteMealDialogOpen]);

  return (
    <>
      {isEditDialogOpen && (
        <EditMealDialog handleEditMealDialog={handleEditMealDialog} data={dialogData} />
      )}
      {isDeleteMealDialogOpen && (
        <DeleteMealDialog handleDeleteMealDialog={handleDeleteMealDialog} data={dialogData} />
      )}
      <div className="meal-category" onClick={() => setIsAccordionCollapsed(!isAccordionCollapsed)}>
        ({filteredData?.length}) {categoryName}
      </div>
      <div className={isAccordionCollapsed ? 'meal-items collapsed' : 'meal-items'}>
        {filteredData?.map((el) => {
          return (
            <table className="meal-items__item" key={el.id}>
              <tbody>
                <tr>
                  <th>Hranivo</th>
                  <th>{TABLE_MEASUREMENT_CONSTANT}</th>
                </tr>
                {el?.nutrients?.map((el) => {
                  return (
                    <tr key={el.id}>
                      <td>{el.name}</td>
                      <td>
                        {el.mealnutrient.nutrientQuantity}
                        {NUTRIENT_MEASUREMENT_CONSTANT}
                      </td>
                    </tr>
                  );
                })}
                {el?.milkLitres != 0 && (
                  <tr>
                    <td colSpan={2}>
                      Obrok zadovoljava do <b>{el.milkLitres}l</b> mleka
                    </td>
                  </tr>
                )}
                <tr>
                  <td onClick={() => handleEditMealDialog(el)}>
                    <MdEdit size={25} />
                  </td>
                  <td onClick={() => handleDeleteMealDialog(el)}>
                    <MdDelete size={25} />
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}
      </div>
    </>
  );
};

export default MealCategory;
