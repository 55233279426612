import { useEffect, useState } from 'react';
import axiosInstance from '../../api/axios';
import { MdEdit } from 'react-icons/md';
import EditDialog from './EditDialog';

const MilkAnalysis = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    month: 'januar',
    milkQuantity: 0,
    fat: 0,
    proteins: 0,
    dryMatter: 0,
    numberOfSomaticCells: 0,
    numberOfMicroorganisms: 0
  });
  const [fcm, setFcm] = useState(0);
  const [ecm, setEcm] = useState(0);
  const [mealQualities, setMealQualities] = useState([]);
  const [selectedMilkAnalysisData, setSelectedMilkAnalysisData] = useState({});

  const getMealQualities = async () => {
    const res = await axiosInstance.get(`milkQuality/byUser`);
    setMealQualities(res.data);
  };

  const handleUserInputChange = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.name === 'month' ? e.target.value : Number(e.target.value)
    });
  };

  const handleModal = (data) => {
    setSelectedMilkAnalysisData(data);
    setIsDialogOpen(true);
  };

  const convertPercentageToKG = (property) => inputValues.milkQuantity * (property / 100);

  const postMilkQuality = async () => {
    await axiosInstance.post('milkQuality', {
      ...inputValues,
      fcm,
      ecm
    });
    getMealQualities();
  };

  useEffect(() => {
    getMealQualities();
  }, [isDialogOpen]);

  useEffect(() => {
    setFcm(0.4 * inputValues.milkQuantity + 15 * convertPercentageToKG(inputValues.fat));
    setEcm(
      0.323 * inputValues.milkQuantity +
        12.82 * convertPercentageToKG(inputValues.fat) +
        7.13 * convertPercentageToKG(inputValues.proteins)
    );
  }, [inputValues]);

  return (
    <>
      <div className="milkanalysis-window__body-wrapper">
        <div className="body-wrapper__header">
          <h2>Analiza mleka</h2>
        </div>
        <div className="milkanalysis-window__2cols-wrapper">
          <div className="milkanalysis-window__2cols-wrapper-left">
            <div className="milkanalysis-window__input-wrapper">
              <label>Mesec:</label>

              <select className="dialog__input" name="month" onChange={handleUserInputChange}>
                <option value="Januar">Januar</option>
                <option value="Februar">Februar</option>
                <option value="Mart">Mart</option>
                <option value="April">April</option>
                <option value="Maj">Maj</option>
                <option value="Jun">Jun</option>
                <option value="Jul">Jul</option>
                <option value="Avgust">Avgust</option>
                <option value="Septembar">Septembar</option>
                <option value="Oktobar">Oktobar</option>
                <option value="Novembar">Novembar</option>
                <option value="Decembar">Decembar</option>
              </select>
            </div>

            <div className="milkanalysis-window__input-wrapper">
              <label>Količina mleka (kg):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="milkQuantity"
                className="milkanalysis-window__input"
                placeholder="kg"
                value={inputValues.milkQuantity}
                onChange={handleUserInputChange}
              />
            </div>

            <div className="milkanalysis-window__input-wrapper">
              <label>Mast (%):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="fat"
                className="milkanalysis-window__input"
                placeholder="%"
                value={inputValues.fat}
                onChange={handleUserInputChange}
              />
            </div>

            <div className="milkanalysis-window__input-wrapper">
              <label>Protein (%):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="proteins"
                className="milkanalysis-window__input"
                placeholder="%"
                value={inputValues.proteins}
                onChange={handleUserInputChange}
              />
            </div>

            <div className="milkanalysis-window__input-wrapper">
              <label>Suva materija (%):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="dryMatter"
                className="milkanalysis-window__input"
                placeholder="%"
                value={inputValues.dryMatter}
                onChange={handleUserInputChange}
              />
            </div>

            <div className="milkanalysis-window__input-wrapper">
              <label>Broj somatskih ćelija (ml):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="numberOfSomaticCells"
                className="milkanalysis-window__input"
                placeholder="ml"
                value={inputValues.numberOfSomaticCells}
                onChange={handleUserInputChange}
              />
            </div>

            <div className="milkanalysis-window__input-wrapper">
              <label>Broj mikroorganizama (ml):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="numberOfMicroorganisms"
                className="milkanalysis-window__input"
                placeholder="ml"
                value={inputValues.numberOfMicroorganisms}
                onChange={handleUserInputChange}
              />
            </div>

            <div className="milkanalysis-window__input-wrapper">
              <label>FCM:</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="numberOfMicroorganisms"
                className="milkanalysis-window__input"
                placeholder="ml"
                value={fcm}
                onChange={(e) => e.preventDefault()}
                disabled
              />
            </div>

            <div className="milkanalysis-window__input-wrapper">
              <label>ECM:</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="numberOfMicroorganisms"
                className="milkanalysis-window__input"
                placeholder="ml"
                value={ecm}
                onChange={(e) => e.preventDefault()}
                disabled
              />
            </div>
            <button className="milkanalysis-window__button" onClick={postMilkQuality}>
              Sačuvaj
            </button>
          </div>

          <div className="milkanalysis-window__2cols-wrapper-right">
            <table className="milkanalysis-window__table-milk">
              <tbody>
                <tr>
                  <th>Opcije</th>
                  <th>Mesec</th>
                  <th>Mast</th>
                  <th>Protein</th>
                  <th>Suva materija</th>
                  <th>Broj somatskih ćelija</th>
                  <th>Broj mikroorganizama</th>
                  <th>FCM</th>
                  <th>ECM</th>
                </tr>
                {mealQualities?.map((el) => (
                  <tr key={el.id}>
                    <td>
                      <MdEdit onClick={() => handleModal(el)} />
                    </td>
                    <td>{el.month}</td>
                    <td>{el.fat}%</td>
                    <td>{el.proteins}%</td>
                    <td>{el.dryMatter}%</td>
                    <td>{el.numberOfSomaticCells}ml</td>
                    <td>{el.numberOfMicroorganisms}ml</td>
                    <td>{el.fcm}kg</td>
                    <td>{el.ecm}kg</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!mealQualities[0] && (
              <p className="user-table__no-cows-message">Ne postoje podaci o kvalitetu mleka.</p>
            )}
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <EditDialog
          milkAnalysis={selectedMilkAnalysisData}
          closeDialog={() => {
            setIsDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default MilkAnalysis;
