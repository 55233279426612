import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { loginCowImage } from '../../assets';

const ResetInputEmail = () => {
  const inputEmail = useRef('');
  const [errorMessage, setErrorMessage] = useState('');
  let navigate = useNavigate();

  const postEmail = async () => {
    try {
      await axiosInstance.post(`user/forgotPassword`, {
        email: inputEmail.current.value
      });
      navigate('/login');
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <div className="login">
      <div className="login__left">
        <img src={loginCowImage} alt="" />
      </div>
      <div className="login__wrapper">
        <div className="login__right">
          <h1 className="login__heading">Restartujte lozinku</h1>
          <label>Email adresa naloga:</label>
          <input
            className="login__input-field"
            type="email"
            placeholder="Email adresa naloga"
            ref={inputEmail}
          />
          {errorMessage && <p className="login__under-text">{errorMessage}</p>}
          <button type="button" className="login__button" onClick={postEmail}>
            Pošalji email
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetInputEmail;
