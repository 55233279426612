import { useState, useEffect } from 'react';
import axiosInstance from '../../api/axios';
import { MealCategory } from '../../components';
import AddMealDialog from './AddMealDialog';

const ManageMeals = () => {
  const [mealData, setMealData] = useState([]);
  const [isAddMealDialogOpen, setIsAddMealDialogOpen] = useState(false);

  const getMeals = async () => {
    const response = await axiosInstance.get(`meal/main`);
    setMealData(response.data.meals);
  };

  const handleAddMealDialog = () => {
    setIsAddMealDialogOpen(!isAddMealDialogOpen);
  };

  useEffect(() => {
    getMeals();
  }, [isAddMealDialogOpen]);

  return (
    <>
      {isAddMealDialogOpen && <AddMealDialog handleAddMealDialog={handleAddMealDialog} />}
      <div className="body-wrapper">
        <div className="body-wrapper__header">
          <h2>Upravljaj osnovnim obrocima</h2>
        </div>
        <div className="meal-wrapper">
          <button onClick={() => setIsAddMealDialogOpen(true)} className="meal-wrapper__action-button">
            Dodaj obrok
          </button>
          <MealCategory data={mealData} categoryId="1" categoryName="Početak laktacije (0-70 dana)" categoryType="1" getMeals={getMeals} />
          <MealCategory data={mealData} categoryId="2" categoryName="Vrhunac laktacije (70-140 dana)" categoryType="1" getMeals={getMeals} />
          <MealCategory data={mealData} categoryId="3" categoryName="Od sredine do završetka laktacije (140-305 dana)" categoryType="1" getMeals={getMeals} />
          <MealCategory data={mealData} categoryId="4" categoryName="Zasušenje (45-60 dana pred teljenje)" categoryType="1" getMeals={getMeals} />
        </div>
      </div>
    </>
  );
};

export default ManageMeals;
