import { Outlet, Navigate } from 'react-router-dom';

const token = localStorage.getItem('token');
const userData = JSON.parse(localStorage.getItem('userData'));

export const AdminRoutes = () => {
  return token && userData.status === '7321' ? <Outlet /> : <Navigate to="/login" />;
};

export const UserRoutes = () => {
  return token && userData.status === '5899' ? <Outlet /> : <Navigate to="/login" />;
};
