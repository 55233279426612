import { useState, useEffect } from 'react';
import axiosInstance from '../../api/axios';

const AddExtraMeal = ({ selectedExtraMeal, setSelectedExtraMeal }) => {
  const [meals, setMeals] = useState([]);

  const getMeals = async () => {
    const response = await axiosInstance.get(`meal/extra`);
    setMeals(response.data.meals);
  };

  useEffect(() => {
    getMeals();
  }, []);

  return (
    <div className="dialog__body">
      <div className="list-cow-meals">
        {meals.map((el) => (
          <table
            className={
              selectedExtraMeal.id === el.id
                ? 'list-cow-meals__item list-cow-meals__item--selected'
                : 'list-cow-meals__item'
            }
            key={el.id}>
            <tbody>
              {el.nutrients.map((el2) => {
                return (
                  <tr key={el2.id}>
                    <td>{el2.name}</td>
                  </tr>
                );
              })}
              <tr>
                <td>
                  Obrok sadrži
                  <b>
                    {el.category === '5'
                      ? ' 12% '
                      : el.category === '6'
                      ? ' 15% '
                      : el.category === '7'
                      ? ' 18% '
                      : ''}
                  </b>
                  proteina
                </td>
              </tr>
              <tr>
                <td className="add-meal-button" onClick={() => setSelectedExtraMeal(el)}>
                  Odaberi obrok
                </td>
              </tr>
            </tbody>
          </table>
        ))}
      </div>
    </div>
  );
};

export default AddExtraMeal;
