import { useState, useEffect } from 'react';
import axiosInstance from '../../api/axios';

const AddMainMeal = ({ selectedCowData, selectedMeal, setSelectedMeal }) => {
  const [meals, setMeals] = useState([]);

  const getMeals = async () => {
    const availableMealCategory =
      selectedCowData.lactationDay > 0 && selectedCowData.lactationDay <= 70
        ? '1'
        : selectedCowData.lactationDay > 70 && selectedCowData.lactationDay <= 140
        ? '2'
        : selectedCowData.lactationDay > 140 && selectedCowData.lactationDay <= 305
        ? '3'
        : '4';
    let response = await axiosInstance.get(`meal/main`);
    response = response.data.meals.filter((el) => el.category === availableMealCategory);
    setMeals(response);
  };

  useEffect(() => {
    getMeals();
  }, []);

  return (
    <div className="dialog__body">
      <div className="list-cow-meals">
        {meals.map((el) => {
          return (
            <table
              className={
                selectedMeal.id === el.id
                  ? 'list-cow-meals__item list-cow-meals__item--selected'
                  : 'list-cow-meals__item'
              }
              key={el.id}>
              <tbody>
                {el.nutrients.map((el2) => {
                  return (
                    <tr key={el2.id}>
                      <td>{el2.name}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td>
                    Obrok zadovoljava do <b>{el.milkLitres}l</b> mleka
                  </td>
                </tr>
                <tr>
                  <td className="add-meal-button" onClick={() => setSelectedMeal(el)}>
                    Odaberi obrok
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}
      </div>
    </div>
  );
};

export default AddMainMeal;
