import { useState, useEffect } from 'react';
import axiosInstance from '../../api/axios';
import { MealCategory } from '../../components';
import AddMealDialog from './AddMealDialog';

const ManageExtraMeals = () => {
  const [mealData, setMealData] = useState([]);
  const [isAddExtraMealDialogOpen, setIsAddExtraMealDialogOpen] = useState(false);

  const getExtraMeals = async () => {
    const response = await axiosInstance.get(`meal/extra`);
    setMealData(response.data.meals);
  };

  const handleAddExtraMealDialog = () => {
    setIsAddExtraMealDialogOpen(!isAddExtraMealDialogOpen);
  };

  useEffect(() => {
    getExtraMeals();
  }, [isAddExtraMealDialogOpen]);

  return (
    <>
      {isAddExtraMealDialogOpen && <AddMealDialog handleAddMealDialog={handleAddExtraMealDialog} />}
      <div className="body-wrapper">
        <div className="body-wrapper__header">
          <h2>Upravljaj dopunskim obrocima</h2>
        </div>
        <div className="meal-wrapper">
          <button onClick={() => setIsAddExtraMealDialogOpen(true)} className="meal-wrapper__action-button">
            Dodaj obrok
          </button>
          <MealCategory data={mealData} categoryId="5" categoryName="12% proteina (1kg koncentrata za 2kg mleka)" categoryType="2" getExtraMeals={getExtraMeals} />
          <MealCategory data={mealData} categoryId="6" categoryName="15% proteina (1kg koncentrata za 2.5kg mleka)" categoryType="2" getExtraMeals={getExtraMeals} />
          <MealCategory data={mealData} categoryId="7" categoryName="18% proteina (1kg koncentrata za 3kg mleka)" categoryType="2" getExtraMeals={getExtraMeals} />
        </div>
      </div>
    </>
  );
};

export default ManageExtraMeals;
