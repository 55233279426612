import axiosInstance from '../../api/axios';

const DeleteMealDialog = ({ data, handleDeleteMealDialog }) => {
  const deleteUser = async () => {
    await axiosInstance.patch(`meal/delete/${data.id}`);
    handleDeleteMealDialog();
  };

  return (
    <div className="wrapper">
      <div className="dialog">
        <div className="dialog__header">Ukloni obrok</div>
        <div className="dialog__body">
          <p>Da li ste sigurni da želite da uklonite obrok?</p>
        </div>
        <div className="dialog__footer">
          <button onClick={deleteUser}>Potvrdi</button>
          <button onClick={handleDeleteMealDialog}>Otkaži</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteMealDialog;
