import { useEffect } from 'react';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import Autocomplete from 'react-autocomplete';
import axiosInstance from '../../api/axios';

const AddMealDialog = ({ handleAddMealDialog }) => {
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [nutrients, setNutrients] = useState([]);
  const [inputCategory, setInputCategory] = useState('1');
  const [inputMilkLitres, setInputMilkLitres] = useState(0);
  const [selectedNutrients, setSelectedNutrients] = useState([]);

  const getNutrients = async () => {
    let response = await axiosInstance.get(`nutrient`);
    response = response.data.nutrients.filter((el) => el.category === '1' || el.category === '3');
    setNutrients(response);
  };

  const postMeal = async () => {
    await axiosInstance.post(`meal`, {
      category: inputCategory,
      milkLitres: inputMilkLitres,
      nutrientsInfo: [...selectedNutrients]
    });
    handleAddMealDialog();
  };

  const handleChangeInputFields = (e) => {
    const tempObject = selectedNutrients.map((el) => {
      if (el.id === Number(e.target.id)) {
        return { ...el, nutrientQuantity: e.target.value };
      }
      return el;
    });
    setSelectedNutrients(tempObject);
  };

  const handleSelectNutrients = (name, itemObject) => {
    setSelectedNutrients([
      ...selectedNutrients,
      { id: itemObject.id, name: itemObject.name, nutrientQuantity: '' }
    ]);
  };

  const removeSelectedNutrient = (value) => {
    const tempObject = selectedNutrients?.filter((el) => el.id !== value.id);
    setSelectedNutrients(tempObject);
  };

  useEffect(() => {
    getNutrients();
  }, []);

  return (
    <div className="wrapper">
      <div className="dialog">
        <div className="dialog__header">Dodaj osnovni obrok</div>
        <div className="dialog__body">
          <label>Hraniva:</label>
          <Autocomplete
            getItemValue={(item) => item.name}
            items={nutrients}
            shouldItemRender={(item, value) =>
              item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            renderItem={(item, isHighlighted) => (
              <div
                key={item.id}
                className="dialog__input"
                style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.name}
              </div>
            )}
            value={autocompleteValue}
            onChange={(e) => setAutocompleteValue(e.target.value)}
            onSelect={handleSelectNutrients}
          />
          <div className="nutrient-chip-wrapper">
            {selectedNutrients.map((el) => (
              <div key={el.id} className="nutrient-chip">
                {el.name}
                <MdClose size={15} onClick={() => removeSelectedNutrient(el)} />
              </div>
            ))}
          </div>
          {selectedNutrients[0] &&
            selectedNutrients.map((el) => (
              <div key={el.id}>
                <label>{el.name}:</label>
                <input
                  type="text"
                  placeholder="kg / 100kg"
                  className="dialog__input"
                  id={el.id}
                  value={el.nutrientQuantity}
                  onChange={handleChangeInputFields}
                />
              </div>
            ))}
          <label>Kategorija:</label>
          <select
            className="dialog__input"
            value={inputCategory}
            onChange={(e) => setInputCategory(e.target.value)}>
            <option value="1">Početak laktacije (0-70 dana)</option>
            <option value="2">Vrhunac laktacije (70-140 dana)</option>
            <option value="3">Od sredine do završetka laktacije (140-305 dana)</option>
            <option value="4">Zasušenje (45-60 dana)</option>
          </select>
          <label>Količina mleka:</label>
          <input
            type="number"
            placeholder="kg"
            className="dialog__input"
            value={inputMilkLitres}
            onChange={(e) => setInputMilkLitres(e.target.value)}
          />
        </div>
        <div className="dialog__footer">
          <button onClick={postMeal}>Potvrdi</button>
          <button onClick={handleAddMealDialog}>Otkaži</button>
        </div>
      </div>
    </div>
  );
};

export default AddMealDialog;
