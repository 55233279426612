import axiosInstance from '../../api/axios';

const DeleteUserDialog = ({ data, handleDeleteUserDialog }) => {
  const deleteUser = async () => {
    await axiosInstance.patch(`user/delete/${data.id}`);
    handleDeleteUserDialog();
  };

  return (
    <div className="wrapper">
      <div className="dialog">
        <div className="dialog__header">Ukloni korisnika</div>
        <div className="dialog__body">
          <p>
            Da li ste sigurni da želite da uklonite korisnika {data.firstName} {data.lastName}?
          </p>
        </div>
        <div className="dialog__footer">
          <button onClick={deleteUser}>Potvrdi</button>
          <button onClick={handleDeleteUserDialog}>Otkaži</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserDialog;
