import { useState, useEffect } from 'react';
import axiosInstance from '../../api/axios';
import moment from 'moment';
import { MdEdit, MdLock, MdLockOpen, MdDelete } from 'react-icons/md';
import AddUserDialog from './AddUserDialog';
import EditUserDialog from './EditUserDialog';
import DeactivateUserDialog from './DeactivateUserDialog';
import DeleteUserDialog from './DeleteUserDialog';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [isUserAddDialogOpen, setIsUserAddDialogOpen] = useState(false);
  const [isUserEditDialogOpen, setIsUserEditDialogOpen] = useState(false);
  const [isUserDeactivateDialogOpen, setIsUserDeactivateDialogOpen] = useState(false);
  const [isUserDeleteDialogOpen, setIsUserDeleteDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});

  const getUsers = async () => {
    const res = await axiosInstance.get(`user`);
    setUsers(res.data.users);
  };

  const handleAddUserDialog = () => {
    setIsUserAddDialogOpen(!isUserAddDialogOpen);
  };

  const handleEditUserDialog = (el) => {
    setDialogData(el);
    setIsUserEditDialogOpen(!isUserEditDialogOpen);
  };

  const handleDeactivateUserDialog = (el) => {
    setDialogData(el);
    setIsUserDeactivateDialogOpen(!isUserDeactivateDialogOpen);
  };

  const handleDeleteUserDialog = (el) => {
    setDialogData(el);
    setIsUserDeleteDialogOpen(!isUserDeleteDialogOpen);
  };

  useEffect(() => {
    getUsers();
  }, [isUserAddDialogOpen, isUserEditDialogOpen, isUserDeleteDialogOpen, isUserDeactivateDialogOpen]);

  return (
    <>
      {isUserAddDialogOpen && <AddUserDialog handleAddUserDialog={handleAddUserDialog} />}
      {isUserEditDialogOpen && <EditUserDialog data={dialogData} handleEditUserDialog={handleEditUserDialog} />}
      {isUserDeactivateDialogOpen && <DeactivateUserDialog data={dialogData} handleDeactivateUserDialog={handleDeactivateUserDialog} />}
      {isUserDeleteDialogOpen && <DeleteUserDialog data={dialogData} handleDeleteUserDialog={handleDeleteUserDialog} />}

      <div className="body-wrapper">
        <div className="body-wrapper__header">
          <h2>Upravljaj korisnicima</h2>
        </div>
        <div className="user-table">
          <button className="user-table__action-button" onClick={handleAddUserDialog}>
            Dodaj korisnika
          </button>
          <table className="table">
            <tbody>
              <tr>
                <th>Ime</th>
                <th>Prezime</th>
                <th>Email</th>
                <th>Aktivnost</th>
                <th>Datum Kreiranja</th>
                <th>Broj grla</th>
                <th>Opcije</th>
              </tr>
              {users?.map((el) => (
                <tr key={el.id}>
                  <td>{el.firstName}</td>
                  <td>{el.lastName}</td>
                  <td>{el.email}</td>
                  <td>{el.isActive ? '🟢' : '🔴'}</td>
                  <td>{moment(el.createdAt).format('DD.MM.YYYY.')}</td>
                  <td>{el.cowsNumber}</td>
                  <td>
                    <MdEdit size={25} onClick={() => handleEditUserDialog(el)} />
                    {el.isActive ? <MdLock size={25} onClick={() => handleDeactivateUserDialog(el)} /> : <MdLockOpen size={25} onClick={() => handleDeactivateUserDialog(el)} />}
                    <MdDelete size={25} onClick={() => handleDeleteUserDialog(el)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ManageUsers;
