import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { loginCowImage } from '../../assets';

const ResetPassword = () => {
  const inputPassword = useRef('');
  const inputConfirmPassword = useRef('');
  const [errorMessage, setErrorMessage] = useState('');
  let { passwordResetHash } = useParams();
  let navigate = useNavigate();

  const postNewPassword = async () => {
    if (inputPassword.current.value === inputConfirmPassword.current.value) {
      await axiosInstance.post(`user/passwordReset/${passwordResetHash}`, {
        password: inputPassword.current.value
      });
      navigate('/login');
    } else setErrorMessage('Lozinke se ne poklapaju!');
  };

  return (
    <div className="login">
      <div className="login__left">
        <img src={loginCowImage} alt="" />
      </div>
      <div className="login__wrapper">
        <div className="login__right">
          <h1 className="login__heading">Promenite lozinku</h1>
          <label>Nova lozinka:</label>
          <input
            className="login__input-field"
            type="password"
            placeholder="Nova lozinka"
            ref={inputPassword}
          />
          <label>Ponovite lozinku:</label>
          <input
            className="login__input-field"
            type="password"
            placeholder="Ponovite lozinku"
            ref={inputConfirmPassword}
          />
          {errorMessage && <p className="login__under-text">{errorMessage}</p>}
          <button type="button" className="login__button" onClick={postNewPassword}>
            Sačuvaj lozinku
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
