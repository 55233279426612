import { useState } from 'react';
import axiosInstance from '../../api/axios';

const EditNutrientDialog = ({ data, isDialogOpen, setIsDialogOpen }) => {
  const [inputFields, setInputFields] = useState({
    name: data.name,
    category: data.category,
    price: data.price,
    dryMatter: data.dryMatter,
    crudeLipids: data.crudeLipids,
    celluloseFibers: data.celluloseFibers,
    crudeFiber: data.crudeFiber,
    proteins: data.proteins,
    totalCrudeProtein: data.totalCrudeProtein,
    digestibleCrudeProtein: data.digestibleCrudeProtein,
    netEnergyForLactation: data.netEnergyForLactation,
    metabolicalEnergy: data.metabolicalEnergy,
    vitaminA: data.vitaminA,
    vitaminD: data.vitaminD,
    vitaminE: data.vitaminE,
    vitaminB12: data.vitaminB12,
    holin: data.holin,
    riboflavinB2: data.riboflavinB2,
    nicotinamideB3: data.nicotinamideB3,
    ash: data.ash,
    phosphorus: data.phosphorus,
    calcium: data.calcium,
    magnesium: data.magnesium,
    manganese: data.manganese,
    natrium: data.natrium,
    lysine: data.lysine,
    metonine: data.metonine,
    tryptophan: data.tryptophan,
    threonine: data.threonine
  });

  const putNutrient = async () => {
    await axiosInstance.patch(`nutrient/${data.id}`, {
      ...inputFields
    });
    setIsDialogOpen({ ...isDialogOpen, editNutrient: false });
  };

  const handleChangeInputFields = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  return (
    <div className="wrapper">
      <div className="dialog">
        <div className="dialog__header">Izmeni hranivo</div>
        <div className="dialog__body">
          {Number(inputFields.category) === 1 ||
          Number(inputFields.category) === 2 ||
          Number(inputFields.category) === 3 ? (
            <>
              <p>Ime:</p>
              <input
                type="text"
                placeholder="Ime"
                className="dialog__input"
                name="name"
                value={inputFields.name}
                onChange={handleChangeInputFields}
              />
              <p>Kategorija:</p>
              <select
                className="dialog__input"
                value={inputFields.category}
                name="category"
                onChange={handleChangeInputFields}>
                <option value={1}>Osnovni</option>
                <option value={2}>Dopunski</option>
                <option value={3}>Mešoviti</option>
                <option value={4}>Za koncentrat</option>
              </select>
              <p>Cena:</p>
              <input
                type="number"
                placeholder="Cena"
                className="dialog__input"
                name="price"
                value={inputFields.price}
                onChange={handleChangeInputFields}
              />
              <p>Suva materija:</p>
              <input
                type="number"
                placeholder="kg/kg"
                className="dialog__input"
                name="dryMatter"
                value={inputFields.dryMatter}
                onChange={handleChangeInputFields}
              />
              <p>Sirovi lipidi:</p>
              <input
                type="number"
                placeholder="g/kg"
                className="dialog__input"
                name="crudeLipids"
                value={inputFields.crudeLipids}
                onChange={handleChangeInputFields}
              />
              <p>Celulozna vlakna:</p>
              <input
                type="number"
                placeholder="g/kg"
                className="dialog__input"
                name="celluloseFibers"
                value={inputFields.celluloseFibers}
                onChange={handleChangeInputFields}
              />
              <p>Ukupni sirovi protein:</p>
              <input
                type="number"
                placeholder="g/kg"
                className="dialog__input"
                name="totalCrudeProtein"
                value={inputFields.totalCrudeProtein}
                onChange={handleChangeInputFields}
              />
              <p>Svarljivi sirovi protein:</p>
              <input
                type="number"
                placeholder="g/kg"
                className="dialog__input"
                name="digestibleCrudeProtein"
                value={inputFields.digestibleCrudeProtein}
                onChange={handleChangeInputFields}
              />
              <p>Neto energija za laktaciju:</p>
              <input
                type="number"
                placeholder="MJ/kg"
                className="dialog__input"
                name="netEnergyForLactation"
                value={inputFields.netEnergyForLactation}
                onChange={handleChangeInputFields}
              />
              <p>Pepeo:</p>
              <input
                type="number"
                placeholder="g/kg"
                className="dialog__input"
                name="ash"
                value={inputFields.ash}
                onChange={handleChangeInputFields}
              />
              <p>Fosfor:</p>
              <input
                type="number"
                placeholder="g/kg"
                className="dialog__input"
                name="phosphorus"
                value={inputFields.phosphorus}
                onChange={handleChangeInputFields}
              />
              <p>Kalcijum:</p>
              <input
                type="number"
                placeholder="g/kg"
                className="dialog__input"
                name="calcium"
                value={inputFields.calcium}
                onChange={handleChangeInputFields}
              />
            </>
          ) : (
            <>
              <p>Ime:</p>
              <input
                type="text"
                placeholder="Ime"
                className="dialog__input"
                name="name"
                value={inputFields.name}
                onChange={handleChangeInputFields}
              />
              <p>Kategorija:</p>
              <select
                className="dialog__input"
                value={inputFields.category}
                name="category"
                onChange={handleChangeInputFields}>
                <option value={1}>Osnovni</option>
                <option value={2}>Dopunski</option>
                <option value={3}>Mešoviti</option>
                <option value={4}>Za koncentrat</option>
              </select>
              <p>Cena:</p>
              <input
                type="number"
                placeholder="Cena"
                className="dialog__input"
                name="price"
                value={inputFields.price}
                onChange={handleChangeInputFields}
              />
              <p>Proteini:</p>
              <input
                type="number"
                placeholder="%"
                className="dialog__input"
                name="proteins"
                value={inputFields.proteins}
                onChange={handleChangeInputFields}
              />
              <p>Metabolička energija:</p>
              <input
                type="number"
                placeholder=""
                className="dialog__input"
                name="metabolicalEnergy"
                value={inputFields.metabolicalEnergy}
                onChange={handleChangeInputFields}
              />
              <p>Vitamin A:</p>
              <input
                type="number"
                placeholder="i.j."
                className="dialog__input"
                name="vitaminA"
                value={inputFields.vitaminA}
                onChange={handleChangeInputFields}
              />
              <p>Vitamin D:</p>
              <input
                type="number"
                placeholder="i.j."
                className="dialog__input"
                name="vitaminD"
                value={inputFields.vitaminD}
                onChange={handleChangeInputFields}
              />
              <p>Vitamin E:</p>
              <input
                type="number"
                placeholder="mg/kg"
                className="dialog__input"
                name="vitaminE"
                value={inputFields.vitaminE}
                onChange={handleChangeInputFields}
              />
              <p>Vitamin B12:</p>
              <input
                type="number"
                placeholder=""
                className="dialog__input"
                name="vitaminB12"
                value={inputFields.vitaminB12}
                onChange={handleChangeInputFields}
              />
              <p>Holin:</p>
              <input
                type="number"
                placeholder="mg/kg"
                className="dialog__input"
                name="holin"
                value={inputFields.holin}
                onChange={handleChangeInputFields}
              />
              <p>Riboflavin B2:</p>
              <input
                type="number"
                placeholder="mg/kg"
                className="dialog__input"
                name="riboflavinB2"
                value={inputFields.riboflavinB2}
                onChange={handleChangeInputFields}
              />
              <p>Nikotinamid B3:</p>
              <input
                type="number"
                placeholder="mg/kg"
                className="dialog__input"
                name="nicotinamideB3"
                value={inputFields.nicotinamideB3}
                onChange={handleChangeInputFields}
              />
              <p>Fosfor:</p>
              <input
                type="number"
                placeholder="g/kg"
                className="dialog__input"
                name="phosphorus"
                value={inputFields.phosphorus}
                onChange={handleChangeInputFields}
              />
              <p>Kalcijum:</p>
              <input
                type="number"
                placeholder="g/kg"
                className="dialog__input"
                name="calcium"
                value={inputFields.calcium}
                onChange={handleChangeInputFields}
              />
              <p>Mangan:</p>
              <input
                type="number"
                placeholder="Mg/kg"
                className="dialog__input"
                name="manganese"
                value={inputFields.manganese}
                onChange={handleChangeInputFields}
              />
              <p>Lizin:</p>
              <input
                type="number"
                placeholder=""
                className="dialog__input"
                name="lysine"
                value={inputFields.lysine}
                onChange={handleChangeInputFields}
              />
              <p>Metionin:</p>
              <input
                type="number"
                placeholder=""
                className="dialog__input"
                name="metonine"
                value={inputFields.metonine}
                onChange={handleChangeInputFields}
              />
              <p>Triptofan:</p>
              <input
                type="number"
                placeholder=""
                className="dialog__input"
                name="tryptophan"
                value={inputFields.tryptophan}
                onChange={handleChangeInputFields}
              />
              <p>Treonin:</p>
              <input
                type="number"
                placeholder=""
                className="dialog__input"
                name="threonine"
                value={inputFields.threonine}
                onChange={handleChangeInputFields}
              />
            </>
          )}
        </div>
        <div className="dialog__footer">
          <button onClick={putNutrient}>Potvrdi</button>
          <button onClick={() => setIsDialogOpen({ ...isDialogOpen, editNutrient: false })}>
            Otkaži
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditNutrientDialog;
