import axiosInstance from '../../api/axios';

const EditNutrientDialog = ({ data, isDialogOpen, setIsDialogOpen }) => {
  const deleteNutrient = async () => {
    await axiosInstance.patch(`nutrient/delete/${data.id}`);
    setIsDialogOpen({ ...isDialogOpen, deleteNutrient: false });
  };

  return (
    <div className="wrapper">
      <div className="dialog">
        <div className="dialog__header">Ukloni hranivo</div>
        <div className="dialog__body">
          <p>Da li ste sigurni da želite da uklonite hranivo?</p>
        </div>
        <div className="dialog__footer">
          <button onClick={deleteNutrient}>Potvrdi</button>
          <button onClick={() => setIsDialogOpen({ ...isDialogOpen, deleteNutrient: false })}>
            Otkaži
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditNutrientDialog;
