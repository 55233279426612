import { useState } from 'react';
import axiosInstance from '../../api/axios';

const AddCowDialog = ({ isDialogOpen, setIsDialogOpen }) => {
  const [inputFields, setInputFields] = useState({
    breed: '',
    age: '',
    averageDailyMilk: '',
    lactationStartDay: '',
    mass: '',
    RSID: ''
  });

  const handleChangeInputFields = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const postCow = async () => {
    await axiosInstance.post(`cow`, {
      ...inputFields
    });
    setIsDialogOpen({ ...isDialogOpen, addCow: false });
  };

  return (
    <div className="wrapper">
      <div className="dialog">
        <div className="dialog__header">Dodaj kravu</div>
        <div className="dialog__body">
          <label>Rasa:</label>
          <input
            type="text"
            placeholder="Rasa"
            className="dialog__input"
            name="breed"
            value={inputFields.breed}
            onChange={handleChangeInputFields}
          />
          <label>Starost (u godinama):</label>
          <input
            type="text"
            placeholder="Starost u godinama"
            className="dialog__input"
            name="age"
            value={inputFields.age}
            onChange={handleChangeInputFields}
          />
          <label>Prosečna mlečnost (u litrama):</label>
          <input
            type="text"
            placeholder="Prosečna mlečnost"
            className="dialog__input"
            name="averageDailyMilk"
            value={inputFields.averageDailyMilk}
            onChange={handleChangeInputFields}
          />
          <label>Datum početka laktacije (datum poslednjeg teljenja):</label>
          <input
            type="date"
            placeholder="Datum početka laktacije"
            className="dialog__input"
            name="lactationStartDay"
            value={inputFields.lactationStartDay}
            onChange={handleChangeInputFields}
          />
          <label>Težina (u kilogramima):</label>
          <input
            type="text"
            placeholder="Težina"
            className="dialog__input"
            name="mass"
            value={inputFields.mass}
            onChange={handleChangeInputFields}
          />
          <label>RS Broj:</label>
          <input
            type="text"
            placeholder="RS Broj"
            className="dialog__input"
            name="RSID"
            value={inputFields.RSID}
            onChange={handleChangeInputFields}
          />
        </div>
        <div className="dialog__footer">
          <button onClick={postCow}>Potvrdi</button>
          <button
            onClick={() => {
              setIsDialogOpen({ ...isDialogOpen, addCow: false });
            }}>
            Otkaži
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCowDialog;
