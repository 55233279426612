import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { loginCowImage } from '../../assets';

const Login = () => {
  const inputEmail = useRef('');
  const inputPassword = useRef('');
  const [errorMessage, setErrorMessage] = useState('');

  const postLogin = async () => {
    try {
      const res = await axiosInstance.post(`user/login`, {
        email: inputEmail.current.value,
        password: inputPassword.current.value
      });
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('userData', JSON.stringify(res.data.user));
      window.location.reload();
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <div className="login">
      <div className="login__left">
        <img src={loginCowImage} alt="" />
      </div>
      <div className="login__wrapper">
        <div className="login__right">
          <h1 className="login__heading">Prijava</h1>
          <label>Email adresa:</label>
          <input
            className="login__input-field"
            type="email"
            placeholder="Email adresa"
            ref={inputEmail}
          />
          <label>Lozinka:</label>
          <input
            className="login__input-field"
            type="password"
            placeholder="Lozinka"
            ref={inputPassword}
          />
          {errorMessage && <p className="login__under-text">{errorMessage}</p>}
          <button type="button" className="login__button" onClick={postLogin}>
            Prijavi se
          </button>
          <p className="login__under-text">
            Nemate nalog? <Link to="/register">Kreirajte besplatno</Link>
          </p>
          <p className="login__under-text">
            Zaboravljena lozinka? <Link to="/reset-password">Restartujte lozinku</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
