import { useState } from 'react';
import axiosInstance from '../../api/axios';

const AddUserDialog = ({ handleAddUserDialog }) => {
  const [inputFields, setInputFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });

  const handleChangeInputFields = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const postUser = async () => {
    await axiosInstance.post(`user`, {
      ...inputFields
    });
    handleAddUserDialog();
  };

  return (
    <div className="wrapper">
      <div className="dialog">
        <div className="dialog__header">Dodaj korisnika</div>
        <div className="dialog__body">
          <label>Ime:</label>
          <input
            type="text"
            placeholder="Ime"
            className="dialog__input"
            name="firstName"
            value={inputFields.firstName}
            onChange={handleChangeInputFields}
          />
          <label>Prezime:</label>
          <input
            type="text"
            placeholder="Prezime"
            className="dialog__input"
            name="lastName"
            value={inputFields.lastName}
            onChange={handleChangeInputFields}
          />
          <label>Email adresa:</label>
          <input
            type="email"
            placeholder="Email adresa"
            className="dialog__input"
            name="email"
            value={inputFields.email}
            onChange={handleChangeInputFields}
          />
          <label>Lozinka:</label>
          <input
            type="password"
            placeholder="Lozinka"
            className="dialog__input"
            name="password"
            value={inputFields.password}
            onChange={handleChangeInputFields}
          />
        </div>
        <div className="dialog__footer">
          <button onClick={postUser}>Povrdi</button>
          <button onClick={handleAddUserDialog}>Otkaži</button>
        </div>
      </div>
    </div>
  );
};

export default AddUserDialog;
