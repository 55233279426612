const MealTabs = ({ selectedTab, setSelectedTab }) => {
  const handleTabSelection = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  const tabs = [
    {
      id: 0,
      name: 'Osnovni obrok'
    },
    {
      id: 1,
      name: 'Dopunski obrok'
    }
  ];

  return (
    <div className="dialog__tab-wrapper">
      {tabs.map((el) => (
        <div
          key={el.id}
          className={selectedTab === el.id ? 'dialog__tab--active' : 'dialog__tab'}
          onClick={() => handleTabSelection(el.id)}>
          {el.name}
        </div>
      ))}
    </div>
  );
};

export default MealTabs;
