import { useState, useEffect } from 'react';
import axiosInstance from '../../api/axios';
import nutritiveValues from './nutritiveValues.json';

const MakeConcentrate = () => {
  const [nutrients, setNutrients] = useState([]);
  const [totalNutrientPercentage, setTotalNutrientPercentage] = useState(0);

  const getNutrients = async () => {
    let response = await axiosInstance.get(`nutrient`);
    response = response.data.nutrients.filter((el) => el.category === '4');
    response = response.map((el) => {
      return { ...el, nutrientPercentage: 0 };
    });
    setNutrients(response);
  };

  const handleChangeInputFields = (e) => {
    const tempObject = nutrients.map((el) => {
      if (el.id === Number(e.target.name)) {
        return { ...el, nutrientPercentage: Number(e.target.value) };
      } else {
        return { ...el };
      }
    });
    setNutrients(tempObject);
  };

  const setCustomNutrientPrice = (e) => {
    const tempObject = nutrients.map((el) => {
      if (el.id === Number(e.target.name)) {
        return { ...el, price: Number(e.target.value) };
      } else {
        return { ...el };
      }
    });
    setNutrients(tempObject);
  };

  useEffect(() => {
    getNutrients();
  }, []);

  useEffect(() => {
    setTotalNutrientPercentage(nutrients.reduce((acc, el) => acc + el.nutrientPercentage, 0));
  }, [nutrients]);

  return (
    <div className="body-wrapper">
      <div className="body-wrapper__header">
        <h2>Napravi koncentrat:</h2>
        <p className="helper-text">
          Unesite količinu hraniva u %, zatim će Vam biti prikazane nutritivne vrednosti koncentrata
        </p>
      </div>
      <div className="calculation-window__2cols-wrapper">
        <div className="calculation-window__2cols-wrapper-left">
          <div className="col-3">
            {nutrients.map((el) => (
              <div className="calculation-window__input-wrapper" key={el.id}>
                <div className="col-2">
                  <label>{el.name} (%):</label>
                  <input
                    name={el.id}
                    min="0"
                    max="9999"
                    type="number"
                    placeholder=""
                    className="calculation-window__input"
                    value={el.nutrientPercentage}
                    onChange={handleChangeInputFields}
                  />
                </div>
                <div className="col-2">
                  <label>Cena (rsd/kg):</label>
                  <input
                    name={el.id}
                    min="0"
                    max="9999"
                    type="number"
                    placeholder=""
                    className="calculation-window__input"
                    value={el.price}
                    onChange={setCustomNutrientPrice}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="calculation-window__2cols-wrapper-right">
          <div className="col-3 background-grey">
            {nutritiveValues.map((el) => (
              <div className="calculation-window__input-wrapper" key={el.id}>
                <label>{el.label}:</label>
                <input
                  min="0"
                  max="9999"
                  type="number"
                  placeholder={el.label}
                  className="calculation-window__input"
                  disabled
                  value={nutrients
                    .reduce((acc, el2) => acc + (el2.nutrientPercentage * el2[el.key]) / 100, 0)
                    .toFixed(2)}
                />
              </div>
            ))}
          </div>
          <div className="calculation-window__result-wrapper">
            <p className="calculation-window__total-price">
              Cena/kg obroka:{' '}
              {nutrients
                .reduce((acc, el2) => acc + (el2.nutrientPercentage * el2.price) / 100, 0)
                .toFixed(1)}{' '}
              rsd
            </p>
            {totalNutrientPercentage > 100 ? (
              <p className="helper-text--invalid">
                Zbir procenata svih hraniva ne bi trebalo da prelazi 100%
              </p>
            ) : null}
            <p
              className={
                `calculation-window__total-percentage` +
                (totalNutrientPercentage > 100
                  ? `--invalid`
                  : totalNutrientPercentage < 100
                  ? `--insufficient`
                  : `--sufficient`)
              }>
              Ukupan procenat = {totalNutrientPercentage}%
            </p>
            {totalNutrientPercentage > 100 ? (
              <p className="helper-text--invalid">
                Zbir procenata svih hraniva ne bi trebalo da prelazi 100%
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeConcentrate;
