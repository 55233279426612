import { useEffect } from 'react';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import Autocomplete from 'react-autocomplete';
import axiosInstance from '../../api/axios';

const EditMealDialog = ({ data, handleEditMealDialog }) => {
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [nutrients, setNutrients] = useState([]);
  const [inputCategory, setInputCategory] = useState(data.category);
  const [inputMilkLitres, setInputMilkLitres] = useState(data.milkLitres);
  const [selectedNutrients, setSelectedNutrients] = useState([...data.nutrients]);

  const getNutrients = async () => {
    let response = await axiosInstance.get(`nutrient`);
    response = !data.isExtra
      ? response.data.nutrients.filter((el) => el.category === '1' || el.category === '3')
      : response.data.nutrients.filter((el) => el.category === '2' || el.category === '3');
    setNutrients(response);
  };

  const postMeal = async () => {
    await axiosInstance.patch(`meal/${data.id}`, {
      category: inputCategory,
      milkLitres: inputMilkLitres,
      nutrients: [...selectedNutrients]
    });
    handleEditMealDialog();
  };

  const handleChangeInputFields = (e) => {
    const tempObject = selectedNutrients?.map((el) => {
      if (el.id === Number(e.target.id)) {
        el.mealnutrient.nutrientQuantity = Number(e.target.value);
        el.nutrientQuantity = Number(e.target.value);
        return { ...el };
      } else {
        return { ...el };
      }
    });
    setSelectedNutrients(tempObject);
  };

  const handleSelectNutrients = (name, itemObject) => {
    setSelectedNutrients([
      ...selectedNutrients,
      { id: itemObject.id, name: itemObject.name, mealnutrient: { nutrientQuantity: '' } }
    ]);
  };

  const removeSelectedNutrient = (value) => {
    const tempObject = selectedNutrients?.filter((el) => el.id !== value.id);
    setSelectedNutrients(tempObject);
  };

  useEffect(() => {
    getNutrients();
  }, []);

  return (
    <div className="wrapper">
      <div className="dialog">
        <div className="dialog__header">Izmeni {!data.isExtra ? 'osnovni' : 'dopunski'} obrok</div>
        <div className="dialog__body">
          <label>Hraniva:</label>
          <Autocomplete
            getItemValue={(item) => item.name}
            items={nutrients}
            shouldItemRender={(item, value) =>
              item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            renderItem={(item, isHighlighted) => (
              <div
                key={item.id}
                className="dialog__input"
                style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.name}
              </div>
            )}
            value={autocompleteValue}
            onChange={(e) => setAutocompleteValue(e.target.value)}
            onSelect={handleSelectNutrients}
          />
          <div className="nutrient-chip-wrapper">
            {selectedNutrients?.map((el) => (
              <div key={el.id} className="nutrient-chip">
                {el.name}
                <MdClose size={15} onClick={() => removeSelectedNutrient(el)} />
              </div>
            ))}
          </div>
          {selectedNutrients[0] &&
            selectedNutrients?.map((el) => (
              <div key={el.id}>
                <label>{el.name}:</label>
                <input
                  type="number"
                  placeholder={!data.isExtra ? 'kg / 100kg' : '% / kg'}
                  className="dialog__input"
                  id={el.id}
                  value={el?.mealnutrient?.nutrientQuantity}
                  onChange={handleChangeInputFields}
                />
              </div>
            ))}
          <label>Kategorija:</label>
          {!data.isExtra ? (
            <select
              className="dialog__input"
              value={inputCategory}
              onChange={(e) => setInputCategory(e.target.value)}>
              <option value="1">Početak laktacije (0-70 dana)</option>
              <option value="2">Vrhunac laktacije (70-140 dana)</option>
              <option value="3">Od sredine do završetka laktacije (140-305 dana)</option>
              <option value="4">Zasušenje (45-60 dana)</option>
            </select>
          ) : (
            <select
              className="dialog__input"
              value={inputCategory}
              onChange={(e) => setInputCategory(e.target.value)}>
              <option value="5">12% proteina (2kg mleka)</option>
              <option value="6">15% proteina (2.5kg mleka)</option>
              <option value="7">18% proteina (3kg mleka)</option>
            </select>
          )}
          {!data.isExtra && (
            <>
              <label>Količina mleka:</label>
              <input
                type="number"
                placeholder="kg"
                className="dialog__input"
                value={inputMilkLitres}
                onChange={(e) => setInputMilkLitres(e.target.value)}
              />
            </>
          )}
        </div>
        <div className="dialog__footer">
          <button onClick={postMeal}>Potvrdi</button>
          <button onClick={handleEditMealDialog}>Otkaži</button>
        </div>
      </div>
    </div>
  );
};

export default EditMealDialog;
