import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AdminRoutes, UserRoutes } from './utils';
import {
  Login,
  Register,
  Home,
  ManageUsers,
  ManageMeals,
  ManageExtraMeals,
  ManageNutrients,
  MakeConcentrate,
  MilkAnalysis,
  CalculateBrutoMargin,
  ResetPassword,
  ResetInputEmail
} from './pages';
import { Navigation } from './components';

const token = localStorage.getItem('token');
const userData = JSON.parse(localStorage.getItem('userData'));

const App = () => (
  <Router>
    <Navigation />
    <Routes>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path="admin" element={<AdminRoutes />}>
        <Route path="manage-users" element={<ManageUsers />} exact />
        <Route path="manage-meals" element={<ManageMeals />} exact />
        <Route path="manage-extra-meals" element={<ManageExtraMeals />} exact />
        <Route path="manage-nutrients" element={<ManageNutrients />} exact />
      </Route>
      <Route element={<UserRoutes />}>
        <Route path="home" element={<Home />} exact />
        <Route path="make-concentrate" element={<MakeConcentrate />} exact />
        <Route path="milk-analysis" element={<MilkAnalysis />} exact />
        <Route path="calculate-margin" element={<CalculateBrutoMargin />} exact />
      </Route>
      <Route path="" element={<Navigate to="home" />} exact />
      <Route
        path="login"
        element={
          token && userData.status === '7321' ? (
            <Navigate to="/admin/manage-users" />
          ) : token && userData.status === '5899' ? (
            <Navigate to="/home" />
          ) : (
            <Login />
          )
        }
        exact
      />
      <Route path="register" element={<Register />} exact />
      <Route path="reset-password" element={<ResetInputEmail />} exact />
      <Route path="reset-password/:passwordResetHash" element={<ResetPassword />} exact />
    </Routes>
  </Router>
);

export default App;
