import { useState } from 'react';
import { Link } from 'react-router-dom';
import { navbarLogo } from '../../assets';
import { RiFileList2Line, RiCalculatorLine, RiUser3Line } from 'react-icons/ri';
import { GiCorn } from 'react-icons/gi';
import { MdOutlineLogout } from 'react-icons/md';
import { TbMilk } from 'react-icons/tb';

const Navigation = () => {
  const [selectedNavItem, setSelectedNavItem] = useState('');
  const userData = JSON.parse(localStorage.getItem('userData'));

  const selectNavItemHandler = (e) => {
    setSelectedNavItem(e.target.name);
  };

  return (
    <>
      {userData?.status === '7321' ? (
        <div className="mobile-navigation__wrapper">
          <div className="mobile-navigation">
            <Link to="admin/manage-users">
              <RiUser3Line />
            </Link>
            <Link to="admin/manage-meals">
              <RiFileList2Line />
            </Link>
            <Link to="admin/manage-extra-meals">
              <RiFileList2Line />
            </Link>
            <Link to="admin/manage-nutrients">
              <GiCorn />
            </Link>
            <MdOutlineLogout
              onClick={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('userData');
                window.location.reload();
              }}
            />
          </div>
        </div>
      ) : userData?.status === '5899' ? (
        <div className="mobile-navigation__wrapper">
          <div className="mobile-navigation">
            <Link to="home">
              <RiFileList2Line />
            </Link>
            <Link to="make-concentrate">
              <GiCorn />
            </Link>
            <Link to="calculate-margin">
              <RiCalculatorLine />
            </Link>
            <Link to="milk-analysis">
              <TbMilk />
            </Link>

            <MdOutlineLogout
              onClick={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('userData');
                window.location.reload();
              }}
            />
          </div>
        </div>
      ) : null}
      {userData?.status === '7321' ? (
        <div className="navigation__wrapper">
          <div className="navigation">
            <div className="navigation__logo">
              <img src={navbarLogo} alt="" />
              <h1>Kalkulator Mleka</h1>
            </div>
            <div className="navigation__items">
              <Link
                to="admin/manage-users"
                name="Korisnici"
                onClick={selectNavItemHandler}
                className={selectedNavItem === 'Korisnici' ? 'selectedNavItem' : ''}>
                <RiUser3Line />
                Korisnici
              </Link>
              <Link
                to="admin/manage-meals"
                name="Osnovni obroci"
                onClick={selectNavItemHandler}
                className={selectedNavItem === 'Osnovni obroci' ? 'selectedNavItem' : ''}>
                <RiFileList2Line />
                Osnovni obroci
              </Link>
              <Link
                to="admin/manage-extra-meals"
                name="Dopunski obroci"
                onClick={selectNavItemHandler}
                className={selectedNavItem === 'Dopunski obroci' ? 'selectedNavItem' : ''}>
                <RiFileList2Line />
                Dopunski obroci
              </Link>
              <Link
                to="admin/manage-nutrients"
                name="Hraniva"
                onClick={selectNavItemHandler}
                className={selectedNavItem === 'Hraniva' ? 'selectedNavItem' : ''}>
                <GiCorn />
                Hraniva
              </Link>{' '}
              <button
                type="button"
                onClick={() => {
                  localStorage.removeItem('token');
                  localStorage.removeItem('userData');
                  window.location.reload();
                }}>
                Odjavi se
                <MdOutlineLogout />
              </button>
            </div>
          </div>
        </div>
      ) : userData?.status === '5899' ? (
        <div className="navigation__wrapper">
          <div className="navigation">
            <div className="navigation__logo">
              <img src={navbarLogo} alt="" />
              <h1>Kalkulator Mleka</h1>
            </div>
            <div className="navigation__items">
              <Link
                to="home"
                onClick={selectNavItemHandler}
                name="Početna"
                className={selectedNavItem === 'Početna' ? 'selectedNavItem' : ''}>
                <RiFileList2Line />
                Početna
              </Link>
              <Link
                to="make-concentrate"
                name="Napravi koncentrat"
                onClick={selectNavItemHandler}
                className={selectedNavItem === 'Napravi koncentrat' ? 'selectedNavItem' : ''}>
                <GiCorn />
                Napravi koncentrat
              </Link>
              <Link
                to="calculate-margin"
                name="Izračunaj bruto maržu"
                onClick={selectNavItemHandler}
                className={selectedNavItem === 'Izračunaj bruto maržu' ? 'selectedNavItem' : ''}>
                <RiCalculatorLine />
                Izračunaj bruto maržu
              </Link>
              <Link
                to="milk-analysis"
                name="Analiza mleka"
                onClick={selectNavItemHandler}
                className={selectedNavItem === 'Analiza mleka' ? 'selectedNavItem' : ''}>
                <TbMilk />
                Analiza mleka
              </Link>
              <button
                type="button"
                onClick={() => {
                  localStorage.removeItem('token');
                  localStorage.removeItem('userData');
                  window.location.reload();
                }}>
                Odjavi se
                <MdOutlineLogout />
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Navigation;
