import { useState } from 'react';
import axiosInstance from '../../api/axios';
import React from 'react';

const EditDialog = ({ milkAnalysis, closeDialog }) => {
  const [inputValues, setInputValues] = useState({
    month: milkAnalysis.month,
    fat: milkAnalysis.fat,
    proteins: milkAnalysis.proteins,
    dryMatter: milkAnalysis.dryMatter,
    numberOfSomaticCells: milkAnalysis.numberOfSomaticCells,
    numberOfMicroorganisms: milkAnalysis.numberOfMicroorganisms,
    result: milkAnalysis.result
  });

  const handleChangeInputFields = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const patchMilkAnalysis = async () => {
    await axiosInstance.patch(`milkQuality/${milkAnalysis.id}`, {
      ...inputValues
    });
    closeDialog();
  };

  return (
    <>
      <div className="wrapper">
        <div className="dialog">
          <div className="dialog__header">Uredi analizu mleka</div>
          <div className="dialog__body">
            <div className="milkanalysis-window__input-wrapper">
              <label>Mesec:</label>
              <select value={inputValues.month} onChange={handleChangeInputFields} name="month">
                <option value="januar">Januar</option>
                <option value="februar">Februar</option>
                <option value="mart">Mart</option>
                <option value="april">April</option>
                <option value="maj">Maj</option>
                <option value="jun">Jun</option>
                <option value="jul">Jul</option>
                <option value="avgust">Avgust</option>
                <option value="septembar">Septembar</option>
                <option value="oktobar">Oktobar</option>
                <option value="novembar">Novembar</option>
                <option value="decembar">Decembar</option>
              </select>
            </div>
            <div className="milkanalysis-window__input-wrapper">
              <label>Mast (%):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="fat"
                className="milkanalysis-window__input"
                value={inputValues.fat}
                onChange={handleChangeInputFields}
                placeholder="%"
              />
            </div>
            <div className="milkanalysis-window__input-wrapper">
              <label>Protein (%):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="proteins"
                className="milkanalysis-window__input"
                value={inputValues.proteins}
                onChange={handleChangeInputFields}
                placeholder="%"
              />
            </div>
            <div className="milkanalysis-window__input-wrapper">
              <label>Suva materija (%):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="dryMatter"
                className="milkanalysis-window__input"
                value={inputValues.dryMatter}
                onChange={handleChangeInputFields}
                placeholder="%"
              />
            </div>
            <div className="milkanalysis-window__input-wrapper">
              <label>Broj somatskih ćelija (mL):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="numberOfSomaticCells"
                className="milkanalysis-window__input"
                value={inputValues.numberOfSomaticCells}
                onChange={handleChangeInputFields}
                placeholder="mL"
              />
            </div>{' '}
            <div className="milkanalysis-window__input-wrapper">
              <label>Broj mikroorganizama (mL):</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="numberOfMicroorganisms"
                className="milkanalysis-window__input"
                value={inputValues.numberOfMicroorganisms}
                onChange={handleChangeInputFields}
                placeholder="mL"
              />
            </div>
            <div className="milkanalysis-window__input-wrapper">
              <label>Rezultat:</label>
              <input
                type="number"
                min="0"
                max="9999"
                name="numberOfMicroorganisms"
                className="milkanalysis-window__input"
                value={inputValues.result}
                onChange={handleChangeInputFields}
              />
            </div>
          </div>
          <div className="dialog__footer">
            <button onClick={patchMilkAnalysis}>Potvrdi</button>
            <button type="button" onClick={closeDialog}>
              Otkaži
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDialog;
