import { useState, useEffect } from 'react';
import axiosInstance from '../../api/axios';
import { MdEdit } from 'react-icons/md';
import { GiWheat } from 'react-icons/gi';
import { HiPlus } from 'react-icons/hi';
import AddCowDialog from './AddCowDialog';
import EditCowDialog from './EditCowDialog';
import AddMealDialog from './AddMealDialog';
import ListCowMealsDialog from './ListCowMealsDialog';

const Home = () => {
  const [cows, setCows] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState({
    addCow: false,
    editCow: false,
    addMeal: false,
    listMeal: false
  });
  const [selectedCowData, setSelectedCowData] = useState({});

  const getCows = async () => {
    const res = await axiosInstance.get(`cow/byUser`);
    setCows(res.data);
  };

  const handleModal = (dialogType, data) => {
    setSelectedCowData(data);
    setIsDialogOpen({ ...isDialogOpen, [dialogType]: true });
  };

  useEffect(() => {
    getCows();
  }, [isDialogOpen]);

  return (
    <>
      {isDialogOpen.addCow && (
        <AddCowDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
      )}
      {isDialogOpen.editCow && (
        <EditCowDialog
          selectedCowData={selectedCowData}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
      )}
      {isDialogOpen.addMeal && (
        <AddMealDialog
          selectedCowData={selectedCowData}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          setSelectedCowData={setSelectedCowData}
        />
      )}
      {isDialogOpen.listMeal && (
        <ListCowMealsDialog
          selectedCowData={selectedCowData}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          setSelectedCowData={setSelectedCowData}
        />
      )}
      <div className="body-wrapper">
        <div className="body-wrapper__header">
          <h2>Lista krava</h2>
          <p className="helper-text">U tabeli ispod prikazana su sva grla koja Vam pripadaju</p>
        </div>
        <div className="user-table">
          <button className="user-table__action-button" onClick={() => handleModal('addCow')}>
            Dodaj kravu
          </button>
          <table className="table">
            <tbody>
              <tr>
                <th>Opcije</th>
                <th>Rasa</th>
                <th>Starost</th>
                <th>Mlečnost</th>
                <th>Dan laktacije</th>
                <th>Težina</th>
                <th>RS Identifikacioni broj</th>
              </tr>
              {cows?.map((el) => (
                <tr key={el.id}>
                  <td>
                    <GiWheat size={25} onClick={() => handleModal('listMeal', el)} />
                    <HiPlus size={25} onClick={() => handleModal('addMeal', el)} />
                    <MdEdit size={25} onClick={() => handleModal('editCow', el)} />
                  </td>
                  <td>{el.breed}</td>
                  <td>{el.age} godina</td>
                  <td>{el.averageDailyMilk}l</td>
                  <td>{el.lactationDay}</td>
                  <td>{el.mass}kg</td>
                  <td>RS{el.RSID}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!cows[0] && (
            <p className="user-table__no-cows-message">
              Ne postoje podaci o grlu. Kliknite na dugme <b>"Dodaj kravu"</b>.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
