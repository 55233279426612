import { useEffect, useState } from 'react';
import axiosInstance from '../../api/axios';

const CalculateBrutoMargin = () => {
  const [cows, setCows] = useState([]);
  const [calcTotalMealExpenses, setCalcTotalMealExpenses] = useState(0);
  const [milkSellingIncome, setMilkSellingIncome] = useState(0);
  const [brutoMargin, setBrutoMargin] = useState(0);
  const [noMealError, setNoMealError] = useState(false);
  const [inputValues, setInputValues] = useState({
    milkQuantity: 0,
    milkSellingPrice: 0,
    otherIncomes: 0,
    otherExpenses: 0
  });
  const [outputValues, setOutputValues] = useState({
    milkSellingIncome: 0,
    totalIncome: 0,
    mealPricePerKilogram: 0,
    mealExpense: 0,
    totalExpenses: 0,
    brutoMargin: 0
  });

  const getAllCows = async () => {
    const res = await axiosInstance.get(`cow/byUser`);
    setCows(res.data);
  };

  const handleUserCowChange = (e) => {
    if (e.target.value !== '[object Object]') {
      let tempObject = JSON.parse(e.target.value);
      tempObject = {
        ...tempObject,
        meals: {
          main: !tempObject?.meals[0]?.isExtra ? tempObject.meals[0] : tempObject.meals[1],
          extra: tempObject?.meals[0]?.isExtra ? tempObject.meals[0] : tempObject.meals[1]
        }
      };
      tempObject.meals.main === undefined ? setNoMealError(true) : setNoMealError(false);

      const mainMealExpensePerKilo = tempObject.meals.main
        ? tempObject.meals.main.nutrients.reduce(
            (acc, el) =>
              acc + el.mealnutrient.nutrientQuantity * el.price * (tempObject.mass / 100),
            0
          )
        : 0;
      const extraMealCategory = tempObject.meals.extra
        ? tempObject.meals.extra?.category === '5'
          ? 2
          : tempObject.meals.extra.category === '6'
          ? 2.5
          : tempObject.meals.extra.category === '7'
          ? 3
          : 0
        : 0;
      const extraCalcIndex =
        tempObject.averageDailyMilk - tempObject.meals.main?.milkLitres < 0
          ? 1
          : tempObject.averageDailyMilk - tempObject.meals.main?.milkLitres === 0
          ? 1
          : (tempObject.averageDailyMilk - tempObject.meals.main?.milkLitres) / extraMealCategory;
      const extraMealExpensePerKilo =
        tempObject.meals.main && tempObject.meals.extra
          ? (tempObject.meals.extra.nutrients.reduce(
              (acc, el) => acc + el.mealnutrient.nutrientQuantity * el.price,
              0
            ) /
              100) *
            extraCalcIndex
          : 0;
      const totalMealExpenses =
        (mainMealExpensePerKilo + extraMealExpensePerKilo) / tempObject.averageDailyMilk;
      setCalcTotalMealExpenses(totalMealExpenses);
    }
    let tempObject = outputValues;
    tempObject = {
      ...outputValues,
      totalIncome: Math.round(inputValues.otherIncomes + milkSellingIncome),
      totalExpenses: Math.round(
        calcTotalMealExpenses * inputValues.milkQuantity + inputValues.otherExpenses
      )
    };
    setOutputValues(tempObject);
  };

  const handleUserInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: Number(e.target.value) });
  };

  useEffect(() => {
    setMilkSellingIncome(inputValues.milkQuantity * inputValues.milkSellingPrice);
  }, [inputValues]);

  useEffect(() => {
    let tempObject = outputValues;
    tempObject = {
      ...outputValues,
      totalIncome: Math.round(inputValues.otherIncomes + milkSellingIncome),
      totalExpenses: Math.round(
        calcTotalMealExpenses * inputValues.milkQuantity + inputValues.otherExpenses
      )
    };
    setOutputValues(tempObject);
  }, [milkSellingIncome, inputValues]);

  useEffect(() => {
    setBrutoMargin(
      ((outputValues.totalIncome - outputValues.totalExpenses) / outputValues.totalIncome) * 100
    );
  }, [outputValues]);

  useEffect(() => {
    getAllCows();
  }, []);

  return (
    <div className="body-wrapper">
      <div className="body-wrapper__header">
        <h2>Izračunaj bruto maržu</h2>
        <p className="helper-text">
          Unesite prihode i troškove, zatim će Vam biti prikazan obračun marže
        </p>
      </div>
      <div className="calculation-window__2cols-wrapper">
        <div className="calculation-window__2cols-wrapper-left">
          <div className="calculation-window__input-wrapper">
            <label>Izabrano grlo:</label>
            {noMealError && (
              <p className="helper-text">
                Ne postoji obrok za odabrano grlo. Dodajte obrok pre obračunavanja bruto marže.
              </p>
            )}
            <select className="dialog__input" onChange={handleUserCowChange}>
              <option value={{}}>Odaberite grlo...</option>
              {cows.map((el) => (
                <option key={el.id} value={JSON.stringify(el)}>
                  {el.breed} ({el.averageDailyMilk}l)
                </option>
              ))}
            </select>
          </div>

          <div className="calculation-window__input-wrapper">
            <label>Količina mleka (l):</label>
            <input
              type="number"
              min="0"
              max="9999"
              name="milkQuantity"
              className="calculation-window__input"
              placeholder="kg"
              value={inputValues.milkQuantity}
              onChange={handleUserInputChange}
            />
          </div>

          <div className="calculation-window__input-wrapper">
            <label>Prodajna cena mleka (rsd):</label>
            <input
              type="number"
              min="0"
              max="9999"
              name="milkSellingPrice"
              className="calculation-window__input"
              placeholder="rsd/kg"
              value={inputValues.milkSellingPrice}
              onChange={handleUserInputChange}
            />
          </div>

          <div className="calculation-window__input-wrapper">
            <label>Ostali prihodi - (premije i sl):</label>
            <input
              type="number"
              min="0"
              max="9999"
              name="otherIncomes"
              className="calculation-window__input"
              placeholder="rsd"
              value={inputValues.otherIncomes}
              onChange={handleUserInputChange}
            />
          </div>

          <div className="calculation-window__input-wrapper">
            <label>Ostali troškovi - (struja, voda, vet. troškovi i sl):</label>
            <input
              type="number"
              min="0"
              max="9999"
              name="otherExpenses"
              className="calculation-window__input"
              placeholder="rsd"
              value={inputValues.otherExpenses}
              onChange={handleUserInputChange}
            />
          </div>
        </div>
        <div className="calculation-window__2cols-wrapper-right">
          <div className="col-2">
            <div className="calculation-window__input-wrapper">
              <label>Prihod od prodaje mleka:</label>
              <input type="text" disabled placeholder={`${milkSellingIncome.toFixed()} rsd`} />
            </div>
            <div className="calculation-window__input-wrapper">
              <label>Ukupan prihod:</label>
              <input
                type="text"
                disabled
                placeholder={`${outputValues.totalIncome.toFixed()} rsd`}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="calculation-window__input-wrapper">
              <label>Trošak ishrane:</label>
              <input
                type="text"
                disabled
                placeholder={`${(calcTotalMealExpenses * inputValues.milkQuantity).toFixed()} rsd`}
              />
            </div>
            <div className="calculation-window__input-wrapper">
              <label>Ukupan trošak:</label>
              <input
                type="text"
                disabled
                placeholder={`${outputValues.totalExpenses.toFixed()} rsd`}
              />
            </div>
          </div>
          <div className="calculation-window__input-wrapper">
            <label>Bruto marža:</label>
            <input
              type="text"
              disabled
              placeholder={`${brutoMargin ? brutoMargin.toFixed(1) : 0} %`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculateBrutoMargin;
