import { useState } from 'react';
import axiosInstance from '../../api/axios';
import AddExtraMeal from './AddExtraMeal';
import AddMainMeal from './AddMainMeal';
import MealTabs from './MealTabs';
import { MdClose } from 'react-icons/md';

const AddMealDialog = ({ selectedCowData, isDialogOpen, setIsDialogOpen }) => {
  const [selectedMeal, setSelectedMeal] = useState('');
  const [selectedExtraMeal, setSelectedExtraMeal] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  const postMeal = async () => {
    if (selectedMeal) {
      await axiosInstance.post(`cow/addMainMeal`, {
        cowId: selectedCowData.id,
        mealId: selectedMeal.id
      });
    }
    if (selectedExtraMeal) {
      await axiosInstance.post(`cow/addExtraMeal`, {
        cowId: selectedCowData.id,
        mealId: selectedExtraMeal.id
      });
    }
    setIsDialogOpen({ ...isDialogOpen, addMeal: false });
  };

  return (
    <div className="wrapper">
      <div className="dialog listcowmeals">
        <div className="dialog__header">Dodaj obrok</div>
        <MealTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {selectedTab === 0 && (
          <AddMainMeal
            selectedCowData={selectedCowData}
            selectedMeal={selectedMeal}
            setSelectedMeal={setSelectedMeal}
          />
        )}
        {selectedTab === 1 && (
          <AddExtraMeal
            selectedCowData={selectedCowData}
            selectedExtraMeal={selectedExtraMeal}
            setSelectedExtraMeal={setSelectedExtraMeal}
          />
        )}
        {selectedMeal && selectedExtraMeal && (
          <div className="selected-meal-info">
            Odabrali ste osnovni i dopunski obrok! Potvrdite klikom na dugme ispod.
            <div
              className="remove-meal-button"
              onClick={() => {
                setSelectedExtraMeal('');
                setSelectedMeal('');
              }}>
              <MdClose size={25} />
              Ukloni
            </div>
          </div>
        )}
        {selectedMeal && !selectedExtraMeal && (
          <div className="selected-meal-info">
            Odabrali ste osnovni obrok! Potvrdite klikom na dugme ispod.
            <div className="remove-meal-button" onClick={() => setSelectedMeal('')}>
              <MdClose size={25} />
              Ukloni
            </div>
          </div>
        )}
        {!selectedMeal && selectedExtraMeal && (
          <div className="selected-meal-info">
            Odabrali ste dopunski obrok! Potvrdite klikom na dugme ispod.
            <div className="remove-meal-button" onClick={() => setSelectedExtraMeal('')}>
              <MdClose size={25} />
              Ukloni
            </div>
          </div>
        )}
        {selectedMeal.milkLitres < selectedCowData.averageDailyMilk && !selectedExtraMeal ? (
          <div className="selected-meal-info--red">
            Osnovni obrok ispunjava{' '}
            <b>
              {selectedMeal.milkLitres}/{selectedCowData.averageDailyMilk}
            </b>{' '}
            litara mleka. Dodajte odgovarajući dopunski obrok.
          </div>
        ) : null}
        <div className="dialog__footer">
          <button onClick={postMeal}>Potvrdi</button>
          <button onClick={() => setIsDialogOpen({ ...isDialogOpen, addMeal: false })}>
            Otkaži
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMealDialog;
