import axiosInstance from '../../api/axios';

const DeactivateUserDialog = ({ data, handleDeactivateUserDialog }) => {
  const deactivateUser = async () => {
    if (!data.isActive) {
      await axiosInstance.patch(`user/activate/${data.id}`, {
        isActive: !data.isActive
      });
    } else {
      await axiosInstance.patch(`user/deactivate/${data.id}`, {
        isActive: !data.isActive
      });
    }
    handleDeactivateUserDialog();
  };

  return (
    <div className="wrapper">
      <div className="dialog">
        <div className="dialog__header">Deaktiviraj korisnika</div>
        <div className="dialog__body">
          <p>
            Da li ste sigurni da želite da {data.isActive ? 'deaktivirate' : 'aktivirajte'}{' '}
            korisnika {data.firstName} {data.lastName}?
          </p>
        </div>
        <div className="dialog__footer">
          <button onClick={deactivateUser}>Potvrdi</button>
          <button onClick={handleDeactivateUserDialog}>Otkaži</button>
        </div>
      </div>
    </div>
  );
};

export default DeactivateUserDialog;
