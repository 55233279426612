import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { loginCowImage } from '../../assets';
import axiosInstance from '../../api/axios';

const Register = () => {
  const inputFirstName = useRef('');
  const inputLastName = useRef('');
  const inputEmail = useRef('');
  const inputPassword = useRef('');

  const postRegister = async () => {
    await axiosInstance.post(`user/register`, {
      firstName: inputFirstName.current.value,
      lastName: inputLastName.current.value,
      email: inputEmail.current.value,
      password: inputPassword.current.value
    });
  };

  return (
    <div className="login">
      <div className="login__left">
        <img src={loginCowImage} alt="" />
      </div>
      <div className="login__wrapper">
        <div className="login__right">
          <h1 className="login__heading">Registracija</h1>
          <label>Ime:</label>
          <input className="login__input-field" type="text" placeholder="Ime" ref={inputFirstName} />
          <label>Prezime:</label>
          <input className="login__input-field" type="text" placeholder="Prezime" ref={inputLastName} />
          <label>Email adresa:</label>
          <input className="login__input-field" type="email" placeholder="Email adresa" ref={inputEmail} />
          <label>Lozinka:</label>
          <input className="login__input-field" type="password" placeholder="Lozinka" ref={inputPassword} />
          <button type="button" className="login__button" onClick={postRegister}>
            Registruj se
          </button>
          <p>
            Imate nalog? <Link to="/login">Ulogujte se</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
