import { useState, useEffect } from 'react';
import axiosInstance from '../../api/axios';
import { MdEdit, MdDelete } from 'react-icons/md';
import AddNutrientDialog from './AddNutrientDialog';
import EditNutrientDialog from './EditNutrientDialog';
import DeleteNutrientDialog from './DeleteNutrientDialog';
import NutrientTabs from './NutrientTabs';

const ManageNutrients = () => {
  const [nutrients, setNutrients] = useState([]);
  const [filteredNutrients, setFilteredNutrients] = useState([]);
  const [dialogData, setDialogData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState({
    addNutrient: false,
    editNutrient: false,
    deleteNutrient: false
  });

  const getNutrients = async () => {
    const res = await axiosInstance.get(`nutrient`);
    setNutrients(res.data.nutrients);
  };

  const handleModal = (dialogType, data) => {
    setDialogData(data);
    setIsDialogOpen({ ...isDialogOpen, [dialogType]: true });
  };

  useEffect(() => {
    getNutrients();
    setFilteredNutrients(nutrients.filter((el) => Number(el.category) === selectedTab));
  }, [isDialogOpen]);

  useEffect(() => {
    setFilteredNutrients(nutrients.filter((el) => Number(el.category) === selectedTab));
  }, [nutrients, selectedTab]);

  return (
    <>
      {isDialogOpen.addNutrient && (
        <AddNutrientDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
      )}
      {isDialogOpen.editNutrient && (
        <EditNutrientDialog
          data={dialogData}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
      )}
      {isDialogOpen.deleteNutrient && (
        <DeleteNutrientDialog
          data={dialogData}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
      )}
      <div className="body-wrapper">
        <div className="body-wrapper__header">
          <h2>Upravljaj hranivima</h2>
        </div>
        <div className="nutrient-table-wrapper">
          <div className="nutrient-table__controls">
            <NutrientTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <button
              onClick={() => handleModal('addNutrient')}
              className="nutrient-table__action-button">
              Dodaj hranivo
            </button>
          </div>
        </div>
      </div>
      <table className="nutrient-table">
        <tbody>
          <tr>
            {filteredNutrients[0]?.category === '1' ||
            filteredNutrients[0]?.category === '2' ||
            filteredNutrients[0]?.category === '3' ? (
              <>
                <th>Opcije</th>
                <th>Ime</th>
                <th>Kategorija</th>
                <th>Cena</th>
                <th>Suva materija</th>
                <th>Sirovi lipidi</th>
                <th>Celulozna vlakna</th>
                <th>Ukupni sirovi protein</th>
                <th>Svarljivi sirovi protein</th>
                <th>Neto energija za laktaciju</th>
                <th>Pepeo</th>
                <th>Fosfor</th>
                <th>Kalcijum</th>
              </>
            ) : (
              <>
                <th>Opcije</th>
                <th>Ime</th>
                <th>Kategorija</th>
                <th>Cena</th>
                <th>Proteini</th>
                <th>Metabolička energija</th>
                <th>Lizin</th>
                <th>Metionin</th>
                <th>Treonin</th>
                <th>Triptofan</th>
                <th>Vitamin A</th>
                <th>Vitamin D</th>
                <th>Vitamin E</th>
                <th>Vitamin B12</th>
                <th>Holin</th>
                <th>Riboflavin B2</th>
                <th>Nikotinamid B3</th>
                <th>Kalcijum</th>
                <th>Fosfor</th>
                <th>Mangan</th>
              </>
            )}
          </tr>
          {filteredNutrients[0]?.category === '1' ||
          filteredNutrients[0]?.category === '2' ||
          filteredNutrients[0]?.category === '3'
            ? filteredNutrients?.map((el) => (
                <tr key={el.id}>
                  <td>
                    <MdEdit size={25} onClick={() => handleModal('editNutrient', el)} />
                    <MdDelete size={25} onClick={() => handleModal('deleteNutrient', el)} />
                  </td>
                  <td>{el.name}</td>
                  <td>
                    {el.category === '1'
                      ? 'Osnovni'
                      : el.category === '2'
                      ? 'Dopunski'
                      : el.category === '3'
                      ? 'Mešoviti'
                      : 'Za koncentrat'}
                  </td>
                  <td>{el.price}</td>
                  <td>{el.dryMatter}</td>
                  <td>{el.crudeLipids}</td>
                  <td>{el.celluloseFibers}</td>
                  <td>{el.totalCrudeProtein}</td>
                  <td>{el.digestibleCrudeProtein}</td>
                  <td>{el.netEnergyForLactation}</td>
                  <td>{el.ash}</td>
                  <td>{el.phosphorus}</td>
                  <td>{el.calcium}</td>
                </tr>
              ))
            : filteredNutrients?.map((el) => (
                <tr key={el.id}>
                  <td>
                    <MdEdit size={25} onClick={() => handleModal('editNutrient', el)} />
                    <MdDelete size={25} onClick={() => handleModal('deleteNutrient', el)} />
                  </td>
                  <td>{el.name}</td>
                  <td>
                    {el.category === '1'
                      ? 'Osnovni'
                      : el.category === '2'
                      ? 'Dopunski'
                      : el.category === '3'
                      ? 'Mešoviti'
                      : 'Za koncentrat'}
                  </td>
                  <td>{el.price}</td>
                  <td>{el.proteins}</td>
                  <td>{el.metabolicalEnergy}</td>
                  <td>{el.lysine}</td>
                  <td>{el.metonine}</td>
                  <td>{el.threonine}</td>
                  <td>{el.tryptophan}</td>
                  <td>{el.vitaminA}</td>
                  <td>{el.vitaminD}</td>
                  <td>{el.vitaminE}</td>
                  <td>{el.vitaminB12}</td>
                  <td>{el.holin}</td>
                  <td>{el.riboflavinB2}</td>
                  <td>{el.nicotinamideB3}</td>
                  <td>{el.calcium}</td>
                  <td>{el.phosphorus}</td>
                  <td>{el.manganese}</td>
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
};

export default ManageNutrients;
