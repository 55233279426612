import axios from 'axios';

const token = localStorage.getItem('token');

const axiosInstance = axios.create({
  baseURL: 'https://api.kalkulatormleka.rs/api'
  //baseURL: 'http://localhost:5000/api'
});

axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

export default axiosInstance;
